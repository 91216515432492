<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @keyup.enter="onFilter()"/>
                        </div>
                        <div class="filter-item">
                            <span>TIPE</span>
                            <el-select v-model="filter.tipe" placeholder="Pilih tipe" clearable @change="onFilter()">
                                <el-option v-for="(o, i) in ['WAREHOUSE', 'STORE', 'EVENT']" :key="i" :value="o" :label="o"/>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                        <button class="btn btn-primary" @click="modalClick('add', null)">Create</button>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div class="table-fixed-header sticky" ref="tableRef">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Gambar</th>
                            <th>Nama</th>
                            <th>Tipe</th>
                            <th>Gudang</th>
                            <th>Deskripsi</th>
                            <th>Kode</th>
                            <th>Alamat</th>
                            <th>Status</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>
                                <img v-if="item.logo" :src="img_url_logo+item.logo" class="tbl-image" @click="viewImage(item)" style="object-fit: contain;"/>
                                <span v-else>Belum upload</span>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.tipe }}</td>
                            <td>{{ item.warehouse || '-' }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.store_code }}</td>
                            <td>{{ item.address }}</td>
                            <td>
                                <span class="badge badge-success" v-if="item.status == 1">Aktif</span>
                                <span class="badge badge-danger" v-else>Nonaktif</span>
                            </td>
                            <td class="text-end">
                                <div class="d-flex justify-content-end">
                                    <button @click="modalClick('edit', item)" class="btn btn-secondary btn-sm me-1" title="Edit">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <el-dialog v-model="modal" :title="modalTitle" width="600px" :close-on-click-modal="false">
            <el-form
                ref="formRef"
                :model="tempData"
                class="form w-100 my-5"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Nama</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="name"
                        :rules="[
                          { required: true, message: 'Name is required' },
                        ]"
                      >
                        <el-input v-model="tempData.name" name="name" class="form-control p-0 border-0" placeholder="Masukkan nama" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Gudang</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="warehouse_id"
                        :rules="[
                          { required: false, message: 'Warehouse is required' },
                        ]"
                      >
                        <el-select v-model="tempData.warehouse_id" name="shortname" class="form-control p-0 border-0" placeholder="Pilih gudang" size="large">
                            <el-option
                                v-for="(item, i) in listWarehouse"
                                :key="i"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Deskripsi</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="description"
                        :rules="[
                          { required: true, message: 'Description is required' },
                        ]"
                      >
                        <el-input v-model="tempData.store_code" name="title" class="form-control p-0 border-0" placeholder="Masukkan deskripsi" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Kode</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="store_code"
                        :rules="[
                          { required: false, message: 'Kode is required' },
                        ]"
                      >
                        <el-input v-model="tempData.description" name="title" class="form-control p-0 border-0" placeholder="Masukkan kode" size="large"></el-input>
                      </el-form-item>
                    </div> 
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Address</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="address"
                        :rules="[
                          { required: true, message: 'Address is required' },
                        ]"
                      >
                        <el-input v-model="tempData.address" name="title" class="form-control p-0 border-0" placeholder="Masukkan alamat" size="large"></el-input>
                      </el-form-item>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="required fs-6 fw-bold mb-2">Status</label>
                      <el-form-item
                        class="fv-row mb-7"
                        prop="status"
                        :rules="[
                          { required: true, message: 'Status is required' },
                        ]"
                      >
                        <el-radio-group v-model="tempData.status">
                          <el-radio :label="1" size="large">Aktif</el-radio>
                          <el-radio :label="0" size="large">Nonaktif</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                </div>
                <div class="fv-row mb-7">
                    <div class="fv-row col file">
                        <label class="required fs-6 fw-bold mb-2">Gambar</label>
                        <input v-show="!tempData.logo" @change="onFileChange()" type="file" ref="image" id="image" class="form-control"/>
                        <div v-if="tempData.logo" class="d-flex">
                            <div class="col me-1">
                                <input class="form-control" :value="tempData.logo.substring(tempData.logo.lastIndexOf('/') + 1)">
                            </div>
                            <div class="col ms-1">
                                <button class="btn btn-light text-link" @click="changeImage('polos')">Change Image</button>
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7 file">
                        <div class="file-upload"></div>
                        <div v-show="tempData.logo" class="preview-image">
                            <img id="preview-image" :src="tempData.logo"/>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="button" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="button"
                        ref="submitButton"  
                        class="btn btn-primary"
                        :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                        @click="onSubmit(formRef, submitType)"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination } from 'element-plus'
import { useStore } from 'vuex'
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    components: { 
        // Datatable,
        // Field,
        // Form,
        // ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false);
        const tableRef = ref(null)

        const store = useStore()

        const formRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })
        
        const tableHeader = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Gambar",
                key: "logo",
            },
            {
                name: "Nama Toko",
                key: "name",
            },
            {
                name: "Status",
                key: "status",
            },
            {
                name: "Tipe",
                key: "tipe",
            },
            {
                name: "Short Name",
                key: "shortname",
            },
            {
                name: "Description",
                key: "description",
            },
            {
                name: "store_code",
                key: "store_code",
            },
            {
                name: "Alamat",
                key: "address",
            },
            {
                name: "Aksi",
                key: "action",
            },
        ]);

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const tempData = reactive({
            id: null,
            name: '',
            status: 0,
            tipe: '',
            shortname: '',
            description: '',
            store_code: '',
            address: '',
            logo: '',
            warehouse_id: '',
        })

        const listWarehouse = computed(() => store.getters.getListWarehouse)
        
        const submitType = ref('')

        const modal = ref(false)
        const modalTitle = ref('')
        
        const modalClick = async(type, data = null) => {
            try {
                submitType.value = type
                Object.keys(tempData).map(k => tempData[k] = null)

                if(type == 'add') {
                    modal.value = true
                    modalTitle.value = 'Add New Store'
                    tempData.status = 0
                }
                if(type == 'edit') {
                    await Object.assign(tempData, data);
                    tempData.logo =  img_url_logo.value + '/' + data.logo
                    modal.value = true
                    modalTitle.value = 'Edit Store'
                }

            } catch(e) {
                console.log(e)
            }
        }

        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let tipe = filter.tipe ? '&tipe='+filter.tipe : '';
                let search = filter.search ? '&search='+filter.search : '';

                await ApiService.get("list_store?page=" + page + tipe + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting sales report');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onSubmit = async(formEl, type) => {
            try {
                if (!formEl) return

                await formEl.validate( async(valid, fields) => {
                    loadingSubmit.value = true;

                    if(valid) {
                        const formData = new FormData();
                        let endpoint = '';

                        if(type == 'add') {
                            endpoint = '/store/create';
                        }
                        if(type == 'edit') {
                            endpoint = '/store/update/'+tempData.id;
                        }

                        formData.append('id', tempData.id)
                        formData.append('name', tempData.name)
                        formData.append('shortname', tempData.shortname)
                        formData.append('description', tempData.description)
                        formData.append('tipe', tempData.tipe)
                        formData.append('store_code', tempData.store_code)
                        formData.append('status', tempData.status)
                        formData.append('address', tempData.address)
                        formData.append('image', tempData.logo ? image.value.files[0] : null)
                        formData.append('warehouse_id', tempData.warehouse_id)

                        await ApiService.post(endpoint, formData)
                        .then(async({ data }) => {
                            if(data.error) {
                                throw new Error(data.messages)
                            }

                            modal.value = false

                            Swal.fire({
                                title: data.messages,
                                icon: "success",
                                buttonsStyling: false,
                                showConfirmButton: false,
                                timer: 2000,
                            })

                            await getData()
                        })
                        .catch((response) => {
                            throw new Error(response)
                        });
                    }

                    loadingSubmit.value = false
                })
                

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        // UPLOAD SINGLE FILE
        const image = ref(null)
        const reader = new FileReader();
        const totalSize = ref(0);
        const currentProgress = ref(0);
        function handleEvent(event) {
            if (['loadend', 'load'].includes(event.type)) {
                currentProgress.value = 'Completed 100%';
            }
            if (event.type === 'progress') {
                currentProgress.value = `${(event.loaded / totalSize.value).toFixed(2) * 100}%`;
                //console.log('Loaded: '+event.loaded)
                //console.log('Progress: ', currentProgress.value);
                //console.log('Bytes transferred: ', event.loaded, 'bytes');
            }
            if (event.type === 'loadstart') {
                totalSize.value = event.total;
            }
        }

        function onFileChange(type) {
            var output = document.getElementById('preview-image');

            const file = image.value.files[0];
            tempData.logo = URL.createObjectURL(file);
            output.onload = function() {
                //URL.revokeObjectURL(output.src) // free memory
            }
        }

        function changeImage(type) {
            tempData.logo = ''

            setTimeout(() => { 
                document.getElementById('image').value = null
                document.getElementById('image').click()
            }, 10);
        }

        function addListeners(reader) {
            reader.addEventListener('loadstart', handleEvent); 
            reader.addEventListener('load', handleEvent);
            reader.addEventListener('loadend', handleEvent);
            reader.addEventListener('progress', handleEvent);
            reader.addEventListener('error', handleEvent);
            reader.addEventListener('abort', handleEvent);
        }

        watch(() => tempData.name, (val) => {
            if(val) {
                let splitName = val.split(" ");
                
                if(submitType.value == 'add') {
                    if(splitName.length > 1) {
                        tempData.shortname = splitName.join("_").toLowerCase();
                    } else {
                        tempData.shortname = val.toLowerCase();
                    }
                }
            }
        })

        onBeforeMount(() => {
            store.dispatch(Actions.LIST_WAREHOUSE)
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs("Master Toko", ["Master Toko"]);

            getData()
        })

        return {
            formRef,
            formatIDR,
            loadingTable, loadingSubmit, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            image, onFileChange, changeImage, viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            modal, modalTitle, modalClick,
            pagination, handlePageChange,
            listWarehouse,
            onFilter, resetFilter, filter, filterActive,
            tableHeader, tableData, totalRecordCount, tempData, submitType, onSubmit,
        }
    }
})
</script>